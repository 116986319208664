
  import RubricSumCard from '@/components/RubricSumCard.component.vue';
  import { Classroom } from '@/interfaces/Classroom.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Vue } from 'vue-property-decorator';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import axios from 'axios';

  /**
   * @desc page d'affichage des classes non archivées
   */
  @Component({
    components: {
      'rubric-sum-card' : RubricSumCard
    },
  })
  export default class Classes extends Vue {
    private mobileClass = '';
    private loading = false;

    private classes : Array<Classroom> = [];

    mounted() : void {
      this.loadClasses();
      this.mobileClass = (this as any).$mq === 'mobile' || (this as any).$mq === 'tablet' ? 'mobile' : '';
    }

    /**
     * @desc charge les classes non-archivées à afficher
     */
    private loadClasses() : void {
      const headers = AuthenticationService.getRequestHeader();

      this.loading = true;
      axios
        .get(UrlConsts.getAllUnarchivedClassrooms, headers)
        .then((response) => {
          this.loading = false;
          const classes = response.data as Classroom[];
          this.classes = classes.map(x => new Classroom(x));
        })
        .catch(error => {console.log(error); this.loading = false;})
    }
  }
